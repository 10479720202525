import React from 'react';
import './App.css';
import { PrivateRoute } from './LoginPage'
import { HashRouter as Router, Route } from 'react-router-dom';
import { LoginPage } from './LoginPage';


class App extends React.Component {
  state = {
    authToken: {}
  }
  render() {
    return (
      <div className="App">
        <Router>
          <PrivateRoute exact path="/" component={() => {
            localStorage.removeItem('user');
            window.location.href = 'https://patient-grass-893.animaapp.io/?r=1&p=LE7rT4z&s=desktophd';
            return null;
          }} />
          <Route path="/login" component={LoginPage} />
        </Router>
      </div>
    );
  }
}

export default App;
